import { getAuthToken } from '@testavivadk/utilities'
import { authHeaderName } from '@testavivadk/common-tools/constants'
import {
  fetchBaseQuery, FetchArgs, BaseQueryFn, FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'

// import { applicationUrl } from '@constants'
// import i18n from '../../../i18n'

type FetchBaseQueryArgs = {
  baseUrl?: string
  prepareHeaders?: (headers: Headers, api: {
    getState: () => unknown
  }) => Promise<Headers>
  fetchFn?: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>
} & RequestInit

export const baseQueryWithAuth = ({ baseUrl }: FetchBaseQueryArgs): BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> => async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const authToken = getAuthToken()
      if (authToken != null && authToken && authToken.length > 0) {
        headers.set(authHeaderName, authToken)
      }
      return headers
    }
  })(args, api, extraOptions)

  if (result.meta?.response?.status === 401) {
    // logout user if auth cookie gets expired or invalid and redirect back to spa login page with info message
    // if (import.meta.env.MODE !== 'test') {
    //   const spaLoginUrl = `${window.location.origin}/login?flashWarning=${i18n.t('global.errors.sessionError')}`
    //   window.location.href = `${applicationUrl}/logout?redirect_url=${spaLoginUrl}`
    // }
  }

  return result
}
