import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useLazyLoadRetry } from '@testavivadk/hooks'

import HeaderWrapper from '@frontend-components/components/HeaderWrapper'
import Spinner from '@frontend-components/components/Spinner'
import { Alert } from '@mui/material'

const LazyLoadRetry: FC<{ error: Error }> = ({ error }) => {
  const { t } = useTranslation()
  const [isLoading] = useLazyLoadRetry(error)

  return (
    <>
      <HeaderWrapper />
      {isLoading ? <Spinner position="screen-center" /> : <Alert severity="error">{t('global.errors.genericError')}</Alert>}
    </>
  )
}

export default LazyLoadRetry
